


























































































import { Component, Prop } from 'vue-property-decorator';
import PillWidget from '@/components/pill/PillWidget.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { PillWidget },
  inheritAttrs: false,
})
export default class NextEventWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: null })
  private readonly thumbnail!: { path: string; id: number };

  @Prop({ required: false, default: null })
  private readonly liveText!: string;

  @Prop({ required: false, default: false })
  private readonly live!: boolean;

  private FileResourceHelper = FileResourceHelper;

  created(): void {
    this.setDataConfig();
  }

  private onClick(): void {
    if (this.btnRoute) {
      let external = this.btnRoute.startsWith('//');
      if (external) {
        window.open(this.btnRoute, '_blank');
      } else {
        try {
          const url = new URL(this.btnRoute);
          external = !!url.protocol;
          if (external) {
            window.open(this.btnRoute, '_blank');
          }
        } catch {
          let r = this.btnRoute;
          const matches = this.btnRoute.match(/(%[a-zA-Z-_]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          this.$router.push(r);
        }
      }
    }
  }
}
